<template>
  <div>
    <slot></slot>
    <form
      v-if="ticketCartDetails"
      ref="boleto_auc"
      action="https://mpag.bb.com.br/site/mpag/"
      method="post"
      name="pagamento"
    >
      <input
        type="hidden"
        id="idConv"
        name="idConv"
        v-model="ticketCartDetails.ticketConfig.eCommerceCode"
      />
      <input
        type="hidden"
        id="refTran"
        name="refTran"
        v-model="ticketCartDetails.lastTicketReferenceCodeGeneratedString"
      />
      <input type="hidden" id="valor" name="valor" v-model="ticketCartDetails.formattedValue" />
      <input type="hidden" id="qtdPontos" name="qtdPontos" :value="0" />
      <input type="hidden" id="dtVenc" name="dtVenc" v-model="ticketCartDetails.dueDate" />
      <input type="hidden" id="tpPagamento" name="tpPagamento" value="21" />
      <input type="hidden" id="cpfCnpj" name="cpfCnpj" v-model="ticketCartDetails.person.cpf" />
      <input type="hidden" id="indicadorPessoa" name="indicadorPessoa" value="1" />
      <input
        type="hidden"
        id="tpDuplicata"
        name="tpDuplicata"
        v-model="ticketCartDetails.ticketType"
      />
      <input
        type="hidden"
        id="urlRetorno"
        name="urlRetorno"
        v-model="ticketCartDetails.ticketConfig.returningUrl"
      />
      <input
        type="hidden"
        id="urlInforma"
        name="urlInforma"
        v-model="ticketCartDetails.ticketConfig.informingUrl"
      />
      <input
        type="hidden"
        id="nome"
        name="nome"
        :value="ticketCartDetails.person.name + ' ' + ticketCartDetails.person.lastName"
      />
      <input
        type="hidden"
        id="endereco"
        name="endereco"
        v-model="ticketCartDetails.person.address.street"
      />
      <input
        type="hidden"
        id="cidade"
        name="cidade"
        v-model="ticketCartDetails.person.address.city"
      />
      <input type="hidden" id="uf" name="uf" v-model="ticketCartDetails.person.address.state" />
      <input type="hidden" id="cep" name="cep" v-model="ticketCartDetails.formattedPostalCode" />
      <input
        type="hidden"
        id="msgLoja"
        name="msgLoja"
        v-model="ticketCartDetails.ticketConfig.ticketMessage"
      />
      <input type="submit" value="Imprimir Boleto" class="bto-tb" style="display: none;" />
    </form>
  </div>
</template>

<script type="plain/text">
import TicketCartDetailsViewModel from "@/scripts/models/ticketCartDetailsViewModel.model";
import CommonHelper from "@/scripts/helpers/common.helper";
import { Routes } from "@/scripts/models/enums/routes.enum";
import CartService from "@/scripts/services/cart.service";
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import PaymentMethodProvider from "@/scripts/models/enums/paymentMethodProvider.enum";

export default {
  props: {
    loadFromPromise: Function
  },
  data() {
    return {
      cartService: null,
      ticketCartDetails: new TicketCartDetailsViewModel()
    };
  },
  computed: {
    ...mapGetters(["ticketCart"])
  },
  created() {
    this.cartService = new CartService(null);
    if(this.$route.query.cartId){
      this.cartService.getCartById(this.$route.query.cartId).then(function(cart){
        this.generateTicket(cart);
      }.bind(this))
    }
    else if (this.ticketCart) {
      var cart = this.ticketCart;      
      this.$store.commit("REMOVE_TICKET_CART");
      this.generateTicket(cart);
    } else {
      CommonHelper.swal(
        "",
        "Houve um problema ao gerar seu boleto, por favor entre em contato com o administrador.",
        "error"
      );
    }
  },
  methods: {
    ticketCheckout(cart) {
      return this.cartService.ticketCheckout(cart);
    },
    generateTicketAgain(cart) {
      return this.cartService.generateTicketAgain(cart);
    },
    generateTicket(cart) {
      if (this.$route.query.again) {
        this.generateTicketAgain(cart).then(data => this.submitForm(data));
      } else {
        this.ticketCheckout(cart).then(data => this.submitForm(data));
      }
    },
    submitForm(ticketCartDetailsViewModel) {
      if (ticketCartDetailsViewModel.paymentMethodProvider === PaymentMethodProvider.PagarMe) {
        if(ticketCartDetailsViewModel.ticketUrl === null){
          window.location.reload();
          return;
        }
        window.location.href = ticketCartDetailsViewModel.ticketUrl;
      } else if (
        ticketCartDetailsViewModel.paymentMethodProvider === PaymentMethodProvider.PagSeguro ||
        ticketCartDetailsViewModel.paymentMethodProvider === PaymentMethodProvider.BancoBrasil
      ) {
        this.ticketCartDetails = ticketCartDetailsViewModel;
        Vue.nextTick(
          function() {
            this.$refs.boleto_auc.submit();
          }.bind(this)
        );
      }
    }
  }
};
</script>

<style>
</style>
